@import "../../variables";

.login-page {
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    min-height: 100vh;
    @include easeOut();
    transition-duration: 1s;

    &.n-1 {
        background-image: url(../../assets/home/bg1.jpg);
    }

    &.n-2 {
        background-image: url(../../assets/home/bg2.jpg);
    }

    &.n-3 {
        background-image: url(../../assets/home/bg3.jpg);
    }

    &.n-4 {
        background-image: url(../../assets/home/bg4.jpg);
    }

    &.n-5 {
        background-image: url(../../assets/home/bg5.jpg);
    }

    &.n-6 {
        background-image: url(../../assets/home/bg6.jpg);
    }

    &.n-7 {
        background-image: url(../../assets/home/bg7.jpg);
    }

    &.tenho-conta {
        background-image: url(../../assets/home/bg7.jpg);
        background-color: #000;

        &-cadastro {
            .cadastro-component {
                opacity: 1;
                visibility: visible;
            }
        }

        &-login {
            .login-component {
                opacity: 1;
                visibility: visible;
            }
        }

        &-esqueci {
            .esqueci-senha-component {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .login-component,
    .cadastro-component,
    .esqueci-senha-component {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        bottom: 50px;
        width: 100%;
        padding: 0 25px;
        @include easeOut();
        transition-duration: 1s;
    }

    .box-logo {
        position: absolute;
        text-align: center;
        left: 15px;
        top: 50px;
        width: calc(100% - 30px);

        p {
            margin: 0;
        }

        img {
            display: inline-block;

            &:nth-child(1) {
                width: 25%;
            }

            &:nth-child(2) {
                width: 40%;
            }
        }
    }
}