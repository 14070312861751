@import "../../variables";

.box-sonhos-realizados {
    border-bottom: solid 1px rgba($color: $cinza, $alpha: .2);
    margin-bottom: 25px;

    .titulo {
        margin-bottom: 7px;

        h3 {
            font-size: 16px;
            text-transform: uppercase;
            color: #fff;
        }
    }

    .cards-sonhos {
        overflow-x: auto;
        width: 100%;
        justify-content: flex-start;

        .card-sonhos {
            min-width: 60vw;
            margin-bottom: 15px;
            flex-flow: column;
            margin-right: 15px;
            background: rgba($azulMedio, .7);
            backdrop-filter: blur(5px) brightness(3);
            border: solid 3px rgba($amarelo, .5);
            border-radius: 7px;
            overflow: hidden;
            position: relative;

            .img {
                height: 60vw;
                width: 60vw;
                background-size: cover;
                background-position: center center;
            }

            .box-ico {
                color: #fff;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                text-align: center;

                h4 {
                    display: block;
                    width: 100%;
                    text-shadow: 0 0 3px #000, 0 0 5px #000, 0 0 7px #000, 0 0 10px #000;
                }

                svg {
                    width: 60px;
                    height: 60px;
                    margin: -15px 0;
                    filter: drop-shadow(0 0 4px #000);
                }
            }

        }
    }
}