@import "../../variables";

.box-ultimos-sonhos {
    border-bottom: solid 1px rgba($color: $cinza, $alpha: .2);
    margin-bottom: 25px;

    .titulo {
        margin-bottom: 7px;

        h3 {
            font-size: 16px;
            text-transform: uppercase;
            color: #fff;
        }
    }

    .cards-sonhos {
        overflow-x: auto;
        width: 100%;
        justify-content: flex-start;

        .card-sonhos {
            min-width: 60vw;
            margin-bottom: 15px;
            flex-flow: column;
            margin-right: 15px;
            background: rgba($azulMedio, .7);
            backdrop-filter: blur(5px) brightness(3);
            border: solid 1px rgba(255, 255, 255, .1);
            border-radius: 7px;
            overflow: hidden;

            .txt {
                width: 100%;
                padding: 10px;
                text-align: center;
                color: #fff;

                h2 {
                    font-size: 0.98rem;
                    font-weight: 600;
                }
            }
        }
    }
}