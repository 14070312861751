@import "../../variables";

.sonho.page {
    color: $cinzaEscuro;
    background: url(../../assets/home/bg1.jpg) no-repeat center center / cover;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;

        >.MuiButtonBase-root {
            color: #fff;
            border-bottom: solid 1px;
            border-radius: 0;

            &.bt {
                font-size: 14px;
                padding: 2px 10px;
                border-radius: 5px;
                border: none;
                min-height: auto;
            }
        }

        .esq {
            width: calc(100% - 100px);
        }

        .bt-seta {
            color: #fff;
        }
    }

    .msgs {
        //background: rgba(255, 255, 255, .9);
        //backdrop-filter: blur(5px) brightness(2.5);
        overflow-y: auto;
        max-height: calc(100vh - 225px);
        min-height: calc(100vh - 225px);
        padding: 15px 0;
        display: flex;
        flex-direction: column-reverse;

        .box-msg {
            margin-bottom: 10px;

            &.idream {
                >img {
                    margin-right: 10px;
                }
            }

            >img {
                width: 35px;
                height: 35px;
                margin-left: 10px;
                object-fit: cover;

                &.foto {
                    border-radius: 100%;
                }
            }

            .msg {
                background: #fff;
                border-radius: 4px;
                padding: 10px;

                &.loading {
                    i {
                        width: 50px;
                        height: 15px;
                        position: relative;
                        top: -18px;
                    }
                }
            }
        }
    }

    form {
        width: 100%;
        position: fixed;
        bottom: 70px;
        background: rgba(255, 255, 255, .9);
        backdrop-filter: blur(5px) brightness(2.5);
        border-top: solid 1px #fff;
        padding: 7px 0;

        textarea {
            width: calc(100% - 30px) !important;
            resize: none;
            margin: 0 10px;
            padding: 5px;
            color: $cinzaEscuro;
            background: none;
            height: auto;
            border: none;
            
            &:focus {
                height: 150px;
            }
        }

        .MuiTooltip-tooltip {
            font-size: 12px;
        }

        .MuiButtonBase-root {
            &[disabled] {
                opacity: 0.3;
            }

            &.bt-info {
                color: $azul;
            }

            img {
                width: 20px;
            }
        }
    }
}