@import "../../variables";

section.sonhos-realizados {
    color: $cinzaEscuro;
    background: url(../../assets/home/bg1.jpg) no-repeat center center / cover;

    .cards-sonhos-realizados {
        .card-sonhos-realizados {
            background: rgba($azulMedio, .7);
            backdrop-filter: blur(5px) brightness(3);
            border: solid 1px rgba(255, 255, 255, .1);
            border-radius: 7px;
            overflow: hidden;
            width: 100%;
            margin-bottom: 15px;

            .img {
                width: 50%;
                height: 40vw;
                background-size: cover;
                background-position: center center;
            }

            .box-ico {
                color: #fff;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                text-align: center;

                h4 {
                    display: block;
                    width: 100%;
                    text-shadow: 0 0 3px #000, 0 0 5px #000, 0 0 7px #000, 0 0 10px #000;
                }

                svg {
                    width: 60px;
                    height: 60px;
                    margin: -15px 0;
                    filter: drop-shadow(0 0 4px #000);
                }
            }
        }
    }
}

.sonhos-drawer {
    .MuiBox-root {
        padding: 15px;

        img {
            width: 100%;
            border-radius: 7px;
            margin-bottom: 10px;
        }

        h2 {
            margin-bottom: 10px;
        }

        .data-update {
            margin-bottom: 10px;
            margin-top: -10px;

            small {
                padding-left: 5px;
            }
        }

        .desc {
            padding-bottom: 30px;
        }
    }
}