@import "../../variables";

section.sonhos {
    color: $cinzaEscuro;
    background: url(../../assets/home/bg1.jpg) no-repeat center center / cover;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;

        >.MuiButtonBase-root {
            color: #fff;
            border-bottom: solid 1px;
            border-radius: 0;
        }

        .esq {
            width: calc(100% - 75px);
        }

        .bt-seta {
            color: #fff;
        }
    }

    .cards-sonhos {
        .card-sonhos {
            width: 100%;
            background: rgba(255, 255, 255, .9);
            backdrop-filter: blur(5px) brightness(2.5);
            border-top: solid 1px #fff;
            padding: 15px;
            border-radius: 7px;
            margin-bottom: 15px;
            position: relative;

            h2 {
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: right;
                margin-bottom: 7px;
                padding-bottom: 7px;

                svg {
                    color: $azul;
                    margin-right: 5px;
                }

                small {
                    color: $vermelho;
                }
            }

            .descricao {
                font-size: 14px;
                line-height: 30px;
                max-height: 150px;
                overflow: hidden;

                &.show {
                    max-height: 100%;
                    padding-bottom: 30px;

                    .resposta {

                        .box-bt-mais {
                            padding-top: 10px;
                        }
                    }
                }

                strong {
                    text-transform: uppercase;
                    text-decoration: underline;
                }

                .resposta {

                    .box-bt-mais {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 10px;
                        padding-top: 70px;
                        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #e6e6ea 52%, #e6e6ea 100%);
                        border-radius: 0 0 7px 7px;

                        .ler-mais,
                        .recolher {
                            color: $azulEscuro;
                        }
                    }
                }
            }
        }
    }

    .bt.MuiButton-root {
        margin-top: 15px;
        margin-bottom: 50px;
    }
}

.modal-edit-date {
    align-items: center;
    display: flex;
    justify-content: center;

    .MuiBox-root {
        background: #fff;
        padding: 15px;
        width: calc(100% - 30px);
    }
}