@import "../../variables";

.box-sonhos {
    border-bottom: solid 1px rgba($color: $cinza, $alpha: .2);
    margin-bottom: 25px;

    .titulo {
        margin-bottom: 7px;

        h3 {
            font-size: 16px;
            text-transform: uppercase;
            color: #fff;
        }
    }

    .cards-sonhos {
        overflow-x: auto;
        width: 100%;
        justify-content: flex-start;

        .card-sonhos {
            min-width: 60vw;
            margin-bottom: 15px;
            flex-flow: column;
            margin-right: 15px;
            background: rgba($azulMedio, .7);
            backdrop-filter: blur(5px) brightness(3);
            border: solid 1px rgba(255, 255, 255, .1);
            border-radius: 7px;
            overflow: hidden;

            .img {
                height: 60vw;
                width: 60vw;
                background-size: cover;
                background-position: center center;
            }

            .txt {
                width: 100%;
                padding: 10px;
                text-align: center;
                color: #fff;

                h2 {
                    font-size: 0.98rem;
                    font-weight: 600;
                }

                h3 {
                    font-size: 0.85rem;
                    font-weight: 500;
                    margin: 10px;
                    justify-content: center;

                    >span {
                        margin-right: 10px;

                        span {
                            text-transform: uppercase;
                            font-size: 0.5rem;
                            display: block;
                        }
                    }
                }

                .tags {
                    font-size: 0.73rem;
                }
            }
        }
    }
}