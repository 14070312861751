@import "../../variables.scss";

section.home {
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 90px;
    padding-top: 50px;

    &.n-1 {
        background-image: url(../../assets/home/bg1.jpg);
    }

    &.n-2 {
        background-image: url(../../assets/home/bg2.jpg);
    }

    &.n-3 {
        background-image: url(../../assets/home/bg3.jpg);
    }

    &.n-4 {
        background-image: url(../../assets/home/bg4.jpg);
    }

    &.n-5 {
        background-image: url(../../assets/home/bg5.jpg);
    }

    &.n-6 {
        background-image: url(../../assets/home/bg6.jpg);
    }

    &.n-7 {
        background-image: url(../../assets/home/bg7.jpg);
    }

    .box-lua {
        img {
            width: 70%;
        }
    }

    .box-destaque {
        margin-bottom: 25px;
        padding: 10px;
        border-radius: 7px;
        margin-top: 15px;
        color: #fff;
        background: rgba($azulMedio, .7);
        backdrop-filter: blur(5px) brightness(3);
        border: solid 1px rgba(255, 255, 255, .1);
        overflow: hidden;
        position: relative;

        &.show {
            .txt {
                height: auto;
                padding-bottom: 30px;
            }

            .box-bt-mais {
                padding-top: 10px;
            }
        }

        .img {
            height: 50vw;
            width: 100%;
            background-size: cover;
            background-position: center center;
            border-radius: 5px;
        }

        h2 {
            font-size: 16px;
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 10px;
        }

        .txt {
            font-size: 14px;
            font-weight: 500;
            overflow: hidden;
            height: 150px;

            p {
                margin: 0;
                margin-bottom: 10px;
            }
        }

        .box-bt-mais {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;
            padding-top: 70px;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, $azulEscuro 52%, $azulEscuro 100%);

            .ler-mais,
            .recolher {
                color: #fff;
            }
        }
    }

    .ads-home {
        margin-bottom: 30px;

        .MuiButtonBase-root {
            outline: none;
            border-radius: 10px;
            overflow: hidden;
            border: solid 2px rgba($color: #fff, $alpha: .3);

            img {
                width: 100%;
            }
        }
    }

    .box-bts {
        .bt {
            width: 100%;
            margin-bottom: 15px;
            outline: none;

            &.insta {
                background: linear-gradient(to right, #6b27d2 0%, #e901b6 42%, #ed2b5d 69%, #f51a3e 100%);
            }

            svg {
                margin-right: 7px;
                font-size: 26px;
            }
        }
    }

    .box-cloud {
        color: #fff;
        padding: 25vh 0;

        svg {
            width: 20%;
            height: auto;
            opacity: 0.2;
        }
    }
}