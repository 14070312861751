@import "../../variables";

.meu-perfil.page {
    color: $cinzaEscuro;
    background: url(../../assets/home/bg1.jpg) no-repeat center center / cover;

    .bg-branco {
        background: rgba(255, 255, 255, .9);
        backdrop-filter: blur(5px) brightness(2.5);
        border-top: solid 1px #fff;
        padding: 15px;
        border-radius: 7px;
        margin-bottom: 100px;
    }

    .box-foto {
        margin-bottom: 10px;

        img {
            width: 80px;
            height: 80px;
            border-radius: 100%;
        }

        .edit {
            width: 100%;
            text-align: center;
            position: relative;
            padding-top: 7px;

            .bt-editar {
                background: $cinzaClaro;
                position: absolute;
                top: -15px;
                left: calc(50% - 10px);
                border-radius: 100%;
                padding: 3px;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .MuiTextField-root {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;

        .MuiInputLabel-root {
            color: $cinza;
            text-transform: uppercase;

            &.MuiFormLabel-filled,
            &.Mui-focused {
                color: $cinza;
            }
        }

        .MuiInputBase-input {}

        .MuiOutlinedInput-notchedOutline {
            border: solid 1px $cinza;
        }

        .MuiInputBase-root {
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border-color: $cinza;
                }
            }
        }

    }

    .bt-editar {
        color: $cinza;

        svg {
            &[data-testid="CancelIcon"] {
                margin-left: 7px;
            }
        }
    }

    .bts {
        .bt-editar {
            width: 100%;
            border-bottom: solid 1px;
            border-radius: 0;
            padding: 10px 0;
            margin-bottom: 10px;
            justify-content: flex-start;

            svg {
                margin-right: 10px;
            }
        }
    }
}