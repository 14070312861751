@import "../../variables";

section.diarios {
    color: $cinzaEscuro;
    background: url(../../assets/home/bg1.jpg) no-repeat center center / cover;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;

        >.MuiButtonBase-root {
            color: #fff;
            border-bottom: solid 1px;
            border-radius: 0;
        }

        .esq {
            width: calc(100% - 75px);
        }

        .bt-seta {
            color: #fff;
        }
    }

    .cards-diarios {
        .card-diarios {
            width: 100%;
            background: rgba(255, 255, 255, .9);
            backdrop-filter: blur(5px) brightness(2.5);
            border-top: solid 1px #fff;
            padding: 15px;
            border-radius: 7px;
            margin-bottom: 15px;

            h2 {
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: right;
                margin-bottom: 7px;
                padding-bottom: 7px;

                .MuiButtonBase-root {
                    color: $azul;
                    margin-right: 5px;
                    position: relative;
                    top: -2px;
                }
            }

            .descricao {
                line-height: 4ch;
                background-image: linear-gradient(transparent, transparent calc(4ch - 1px), rgba(0,0,0,0.2) 0px);
                background-size: 100% 4ch;
                font-size: 14px;
            }

            .box-sonho {
                background: #fff;
                padding: 10px;
                margin-bottom: 15px;
                border-radius: 7px;

                h2 {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                .descricao {
                    background: none;
                }
            }

            h3 {
                margin-bottom: 15px;
                font-weight: 500;

                strong {
                    font-weight: 700;
                    color: $azul;
                }
            }

            .bts {
                padding-top: 15px;

                .MuiButtonBase-root {
                    font-size: 14px;
                    width: 100%;
                    margin-bottom: 0;

                    &.remove {
                        background: $vermelho;
                    }

                    svg {
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .bt.MuiButton-root {
        margin-top: 15px;
        margin-bottom: 50px;
    }
}

.modal-buscar {
    align-items: center;
    display: flex;
    justify-content: center;

    .MuiBox-root {
        padding: 15px;
        width: calc(100% - 30px);
        background: rgba(#fff, .8);
        border-radius: 10px;
        backdrop-filter: blur(5px) brightness(1);
        border: solid 1px rgba(255, 255, 255, .1);
        overflow: hidden;
        max-height: 70vh;
        overflow-y: auto;

        .txt-acontecimento {
            padding: 10px;
            border: solid 1px rgba(255, 255, 255, .1);
            background: rgba(#fff, .7);
            border-radius: 7px;
            margin-bottom: 15px;
        }
    }

    .MuiTextField-root {
        margin-bottom: 20px;

        .MuiInputLabel-root {
            color: #000;

            &.MuiFormLabel-filled,
            &.Mui-focused {
                color: #000;
            }
        }
    }

    .cards-sonhos {
        margin-top: 20px;

        .card-sonhos {
            width: 100%;
            background: rgba(255, 255, 255, .9);
            backdrop-filter: blur(5px) brightness(2.5);
            border-top: solid 1px #fff;
            padding: 15px;
            border-radius: 7px;
            margin-bottom: 15px;

            h2 {
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                text-align: right;
                margin-bottom: 7px;
                padding-bottom: 7px;

                svg {
                    color: $azul;
                    margin-right: 5px;
                }

                small {
                    color: $vermelho;
                }
            }

            .descricao {
                font-size: 14px;
                line-height: 30px;

                strong {
                    text-transform: uppercase;
                    text-decoration: underline;
                }
            }

            .bts {
                padding-top: 10px;

                .bt {
                    width: 100%;

                    svg {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}