@import "../../variables";

.bottom-navigation {
    color: #000;
}

body.is-login-page,
body.is-tutorial-page {
    .MuiBottomNavigation-root {
        display: none;
    }
}

.menu-foot {
    position: fixed;
    width: 100%;
    bottom: 0;
}

body {
    .MuiBottomNavigation-root {
        width: 80%;
        padding: 0 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        height: auto !important;
        padding-top: 7px;
        background: rgba(255, 255, 255, .9);
        //backdrop-filter: blur(15px) brightness(2.5);
        backdrop-filter: blur(5px);
        border-radius: 7px 7px 0 0;
        border-top: solid 1px #fff;

        .MuiButtonBase-root.Mui-selected {
            color: $azul;

            &.sol {
                color: $amarelo;
            }

            &.lua {
                color: $roxo;
            }
        }
    }
}