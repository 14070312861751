@mixin exporOut {
    -webkit-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -moz-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    -o-transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    transition: all 700ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
}

@mixin easeOut {
    -webkit-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    -o-transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
    transition: all 500ms cubic-bezier(0.000, 0.000, 0.380, 1.000);
}

$cinza: #636363;
$cinzaClaro: #D9D9D9;
$cinzaBemClaro: #F8F9F9;
$cinzaEscuro: #444957;

$azulEscuro: #000217;
$azulMedio: #191b2e;
$azul: #528aea;

$vermelho: #ea5252;
$amarelo: #d89f1b;
$roxo: #801bd8;