@import "../../variables";

section.ultimos-sonhos {
    color: $cinzaEscuro;
    background: url(../../assets/home/bg1.jpg) no-repeat center center / cover;

    .cards-sonhos-celebres {
        .card-sonhos-celebres {
            background: rgba($azulMedio, .7);
            backdrop-filter: blur(5px) brightness(3);
            border: solid 1px rgba(255, 255, 255, .1);
            border-radius: 7px;
            overflow: hidden;
            width: 100%;
            margin-bottom: 15px;

            .txt {
                width: 100%;
                padding: 10px;
                color: #fff;

                h2 {
                    font-size: 0.98rem;
                    font-weight: 600;
                }

                h3 {
                    font-size: 0.85rem;
                    font-weight: 500;
                    margin: 10px;
                    justify-content: center;

                    >span {
                        margin-right: 10px;

                        span {
                            text-transform: uppercase;
                            font-size: 0.5rem;
                            display: block;
                        }
                    }
                }

                .tags {
                    font-size: 0.73rem;
                }
            }
        }
    }
}

.sonhos-drawer {
    .MuiBox-root {
        padding: 15px;

        img {
            width: 100%;
            border-radius: 7px;
            margin-bottom: 10px;
        }

        h2 {
            margin-bottom: 10px;
        }

        .desc {
            padding-bottom: 30px;
        }
    }
}