@import "../../variables.scss";

section.intro {
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 90px;
    padding-top: 50px;

    &.n-1 {
        background-image: url(../../assets/home/bg1.jpg);
    }

    &.n-2 {
        background-image: url(../../assets/home/bg2.jpg);
    }

    &.n-3 {
        background-image: url(../../assets/home/bg3.jpg);
    }

    &.n-4 {
        background-image: url(../../assets/home/bg4.jpg);
    }

    &.n-5 {
        background-image: url(../../assets/home/bg5.jpg);
    }

    &.n-6 {
        background-image: url(../../assets/home/bg6.jpg);
    }

    &.n-7 {
        background-image: url(../../assets/home/bg7.jpg);
    }

    .container {
        max-width: 800px;
    }

    .box-lua {
        img {
            width: 70%;
            max-width: 200px;
        }
    }

    .box-destaque {
        margin-bottom: 25px;
        padding: 10px;
        border-radius: 7px;
        margin-top: 15px;
        color: #fff;
        background: rgba($azulMedio, .7);
        backdrop-filter: blur(5px) brightness(3);
        border: solid 1px rgba(255, 255, 255, .1);
        overflow: hidden;
        position: relative;

        &.show {
            .txt {
                height: auto;
                padding-bottom: 30px;
            }

            .box-bt-mais {
                padding-top: 10px;
            }
        }

        .img {
            height: 50vw;
            width: 100%;
            background-size: cover;
            background-position: center center;
            border-radius: 5px;
        }

        h2 {
            font-size: 16px;
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 10px;
        }

        .txt {
            font-size: 14px;
            font-weight: 500;
            overflow: hidden;
            height: 150px;

            p {
                margin: 0;
                margin-bottom: 10px;
            }
        }
    }

    .box-bts {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
        
        h3 {
            color: #fff;
            margin: 15px 0;
            border-bottom: solid 1px rgba($color: #fff, $alpha: .2);
            padding-bottom: 3px;
            width: 100%;
            text-align: center;
        }

        .bt {
            width: 100%;
            max-width: 250px;
            margin: 0 10px;
            margin-bottom: 15px;

            svg {
                margin-right: 10px;
            }
        }
    }

    .box-cloud {
        color: #fff;
        padding: 25vh 0;

        svg {
            width: 20%;
            max-width: 130px;
            height: auto;
            opacity: 0.2;
        }
    }
}