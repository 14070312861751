@import "../../variables";

.aconteceu.page {
    color: $cinzaEscuro;
    background: url(../../assets/home/bg1.jpg) no-repeat center center / cover;

    h1 {
        font-size: 16px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;

        >.MuiButtonBase-root {
            color: #fff;
            border-bottom: solid 1px;
            border-radius: 0;

            &.bt {
                font-size: 14px;
                padding: 2px 10px;
                border-radius: 5px;
                border: none;
                min-height: auto;
            }
        }

        .esq {
            width: calc(100% - 100px);
        }

        .bt-seta {
            color: #fff;
        }
    }

    .folhas {
        //overflow-y: auto;
        max-height: calc(100vh - 225px);
        min-height: calc(100vh - 225px);
        padding: 15px 0;
        position: relative;
        top: 25px;

        .folha {
            background: rgba(255, 255, 255, .8);
            backdrop-filter: blur(5px) brightness(2.5);
            padding: 7px 10px;
            border-radius: 7px;
            position: absolute;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            width: 100%;
            height: calc(100vh - 260px);

            &:nth-child(1) {
                transform: translate3d(0, -54px, 0) scale(0.90);
            }

            &:nth-child(2) {
                transform: translate3d(0, -27px, 0) scale(0.95);
            }

            h3 {
                color: $cinzaEscuro;
                text-align: right;
                margin-bottom: 15px;
            }

            .linhas-scroll {
                overflow-y: auto;
                max-height: calc(100vh - 380px);
            }

            ol {
                min-height: calc(100vh - 386px);

                li {
                    line-height: 4ch;
                    background-image: linear-gradient(transparent, transparent calc(4ch - 1px), rgba(0,0,0,0.2) 0px);
                    background-size: 100% 4ch;

                    form {

                        textarea {
                            width: calc(100%) !important;
                            resize: none;
                            padding: 5px 10px;
                            color: $cinzaEscuro;
                            background: #fff;
                            height: auto;
                            border: none;
                            min-height: calc(100vh - 385px);
                            box-shadow: inset 0 0 10px rgba($color: #000000, $alpha: .2);
                            line-height: 130%;
                        }

                        .MuiTooltip-tooltip {
                            font-size: 12px;
                        }

                        .MuiButtonBase-root {
                            &[disabled] {
                                opacity: 0.3;
                            }

                            &.bt-info {
                                color: $azul;
                            }

                            img {
                                width: 20px;
                            }
                        }
                    }
                }
            }

            .foot {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 15px;

                .MuiCircularProgress-root {
                    width: 20px !important;
                    height: 20px !important;
                }

                .MuiButtonBase-root {
                    color: $azul;
                    margin-left: 10px;
                }
            }
        }
    }
}