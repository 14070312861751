@import "../../variables";

header {
    padding: 10px 15px;
    font-size: 20px;
    text-align: center;
    position: fixed;
    width: 100%;
    top: 0;
    border-radius: 0 0 7px 7px;
    //border-bottom: solid 1px rgba($color: #fff, $alpha: .1);
    background: none;
    backdrop-filter: none;
    z-index: 99;
    @include easeOut();

    .bt-profile {
        img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
        }
    }

    .burger {
        svg {
            font-size: 30px;
            color: #fff;
            @include easeOut();
        }
    }

    .bt-logo {
        position: relative;

        img {
            height: 20px;
            width: auto;
        }

        small {
            position: absolute;
            color: rgba($color: #000000, $alpha: .3);
            bottom: -16px;
            left: 0;
            font-size: 8px;
        }
    }
}

body {
    &.is-login-page {
        header {
            display: none;
        }
    }

    &.is-scroll {
        header {
            background: rgba(255, 255, 255, .9);
            //backdrop-filter: blur(15px) brightness(2.5);
            backdrop-filter: blur(5px) brightness(2.5);
            border-bottom: solid 1px #fff;
        }

        .burger {
            svg {
                color: $azul;
            }
        }
    }
}

.MuiDrawer-root {
    .MuiPaper-root.MuiPaper-elevation {
        border-radius: 0 7px 7px 0;
        background-color: rgba(255,255,255,.9);
        backdrop-filter: blur(10px) brightness(3);

        &.MuiDrawer-paperAnchorBottom {
            border-radius: 7px 7px 0 0;
        }

        .MuiListItem-root {
            padding: 0 15px;
            width: 100%;

            .MuiListItemButton-root {
                padding: 10px;
                border-bottom: solid 1px rgba($azul, 0.3);
                color: $azul;
                width: 100%;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    .MuiBox-root {
        width: 100%;

        .MuiList-root {
            width: 100%;
        }
    }
}